import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import ProgressCircle from "../components/ProgressCircle";

import { constants } from "../utils/ranges";

import { ReactComponent as Close } from "../assets/icons/close.svg";
import { ReactComponent as Chevron } from "../assets/icons/Chevron.svg";

import Cover from "../assets/images/report/Cover.png";
import CompanyLogo from "../assets/images/report/CompanyLogo.png";
import AppStore from "../assets/images/report/AppStore.png";
import PlayStore from "../assets/images/report/PlayStore.png";
import QRCode from "../assets/images/report/QRCode.png";
import MindScore from "../assets/images/report/mind-score.png";
import HeartScore from "../assets/images/report/heart-score.png";
import PhysiologicalScore from "../assets/images/report/physiological-score.png";
import KBVBanner from "../assets/images/report/kbv-banner.png";
import RespirationRate from "../assets/images/report/respiration-rate.png";
import OxygenSaturation from "../assets/images/report/oxygen-saturation.png";
import BMI from "../assets/images/report/bmi.png";
import HHBanner from "../assets/images/report/hh-banner.png";
import HeartRate from "../assets/images/report/heart-rate.png";
import SDNN from "../assets/images/report/sdnn.png";
import RMSSD from "../assets/images/report/rmssd.png";
import PNN50 from "../assets/images/report/pnn50.png";
import CardiacOutput from "../assets/images/report/cardiac-output.png";
import MAP from "../assets/images/report/map.png";
import HeartUtilisation from "../assets/images/report/heart-utilisation.png";
import HRMax from "../assets/images/report/hr-max.png";
import HRR from "../assets/images/report/hr-reserve.png";
import THRR from "../assets/images/report/target-hr-range.png";
import VO2MAX from "../assets/images/report/vo2max.png";

import {
  Font,
  Document,
  Page,
  StyleSheet,
  View,
  Text,
  Image,
  pdf,
} from "@react-pdf/renderer";

Font.register({
  family: "Poppins",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8V1tvFP-KUEg.ttf",
      fontWeight: "light",
    },
    {
      src: "https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrFJDUc1NECPY.ttf",
      fontWeight: "normal",
    },
    {
      src: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9V1tvFP-KUEg.ttf",
      fontWeight: "medium",
    },
    {
      src: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6V1tvFP-KUEg.ttf",
      fontWeight: "semibold",
    },
    {
      src: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7V1tvFP-KUEg.ttf",
      fontWeight: "bold",
    },
  ],
});

const PDFReport = ({
  age = 0,
  gender = "",
  height = 0,
  weight = 0,
  result = {
    timestamp: new Date(),
    posture: "resting",
    mind_score: 0,
    heart_score: 0,
    physiological_score: 0,
    health_score: 0,
    resp_rate: 0,
    oxy_sat_prcnt: 0,
    heart_rate: 0,
    bmi: 0,
    sdnn: 0,
    rmssd: 0,
    pNN50_per: 0,
    cardiac_out: 0.0,
    map: 0,
    heart_utilized: 0,
    HRMax: 0,
    HRR: 0,
    THRR: 0,
    vo2max: 0.0,
  },
}) => {
  const healthScoreRangeLabel = constants["Wellbeing Score"].ranges.reduce(
    (p, c) => (result.health_score > c.point ? c : p)
  ).label;

  const styles = StyleSheet.create({
    page: {
      backgroundColor: "#fff",
      flexDirection: "column",
      alignItems: "stretch",
      justifyContent: "space-between",
    },
    header: {
      paddingVertical: 24,
      paddingHorizontal: 40,
      backgroundColor: "#fff",
      borderBottomWidth: 0.5,
      borderBottomColor: "#36BFC9",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    contentContainer: { flex: 1, paddingVertical: 20, paddingHorizontal: 40 },
    rowContainer: { flexDirection: "row", justifyContent: "space-between" },
    largeText: { fontFamily: "Poppins", fontSize: 15, fontWeight: "bold" },
    normalText: { fontFamily: "Poppins", fontSize: 12, fontWeight: "semibold" },
    smallText: { fontFamily: "Poppins", fontSize: 10 },
    footer: {
      paddingVertical: 20,
      paddingHorizontal: 40,
      backgroundColor: "#0D212C",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
  });

  const Range = ({
    ranges = [],
    selectedLabel = "",
    value = "",
    inverted = false,
    diff = 1,
    containerStyle = {},
  }) => {
    const exceptLast = ranges.slice(0, -1);
    const lastPoint = ranges[ranges.length - 1].point;
    return (
      <View
        style={[
          { width: "42.5%", alignSelf: "center", flexDirection: "row" },
          containerStyle,
        ]}
      >
        {exceptLast.map((r, index, list) => (
          <View key={index} style={{ flex: 1 }}>
            <View
              style={[
                { paddingTop: 4, paddingBottom: 2, alignItems: "center" },
                r.label === selectedLabel && {
                  borderTopLeftRadius: 4,
                  borderTopRightRadius: 4,
                  backgroundColor: `${r.color}26`,
                },
              ]}
            >
              <Text
                style={{
                  fontFamily: "Poppins",
                  fontSize: 8,
                  lineHeight: 1.5,
                  color: r.label === selectedLabel ? r.color : "#6E7A80",
                  maxLines: 1,
                  textOverflow: "ellipsis",
                }}
              >
                {r.label}
              </Text>
              <Text
                style={{
                  fontFamily: "Poppins",
                  fontSize: 8,
                  lineHeight: 1.5,
                  color: "#6E7A80",
                  maxLines: 1,
                  textOverflow: "ellipsis",
                }}
              >
                {inverted
                  ? `${r.point + (index > 0) * diff} - ${
                      list[index + 1]?.point || lastPoint
                    }`
                  : `${r.point} - ${
                      list[index + 1]?.point - diff || lastPoint
                    }`}
              </Text>
            </View>
            <View
              style={{
                height: 5,
                backgroundColor: r.color,
                borderTopLeftRadius: index > 0 ? 0 : 20,
                borderBottomLeftRadius: index > 0 ? 0 : 20,
                borderTopRightRadius: index < list.length - 1 ? 0 : 20,
                borderBottomRightRadius: index < list.length - 1 ? 0 : 20,
              }}
            />
            {selectedLabel === r.label && (
              <Text
                style={{
                  marginTop: 4,
                  alignSelf: "center",
                  fontFamily: "Poppins",
                  fontSize: 8,
                  lineHeight: 1.2,
                  color: "#0D212C",
                }}
              >
                {value}
              </Text>
            )}
          </View>
        ))}
      </View>
    );
  };

  const Parameter = ({
    containerStyle = {},
    icon,
    name = "",
    desc = "",
    value = 0,
    unit = "",
    ranges = [],
    rangeConfig = {},
    noRange = false,
  }) => {
    const selectedRange = noRange
      ? undefined
      : ranges.reduce?.((p, c) =>
          (Boolean(rangeConfig.inverted) ? value > c.point : value >= c.point)
            ? c
            : p
        );
    return (
      <View
        style={[
          {
            flexDirection: "row",
            gap: 16,
            paddingTop: 10,
            marginBottom: 16,
            borderTopWidth: 0.5,
            borderTopColor: "#D4D4D4",
          },
          containerStyle,
        ]}
      >
        <Image source={icon} style={{ height: 56, width: 56 }} />
        <View style={{ flex: 1, gap: 6 }}>
          <Text
            style={{
              fontFamily: "Poppins",
              fontSize: 12,
              lineHeight: 1.2,
              fontWeight: "bold",
              color: "#0D212C",
            }}
          >
            {name}
          </Text>
          <Text
            style={{
              fontFamily: "Poppins",
              fontSize: 8,
              lineHeight: 1.2,
              color: "#0D212C",
            }}
          >
            {desc}
          </Text>
        </View>
        {noRange ? (
          <Text
            style={{
              width: "22.5%",
              alignSelf: "center",
              textAlign: "right",
              fontFamily: "Poppins",
              fontSize: 12,
              fontWeight: "semibold",
              color: "#3ABBC7",
            }}
          >
            {value}
          </Text>
        ) : (
          <Range
            ranges={ranges}
            selectedLabel={selectedRange?.label}
            value={`${value} ${unit}`}
            {...rangeConfig}
          />
        )}
      </View>
    );
  };

  return (
    <Document
      author="CarePlix"
      title="Vitals Scan Report | M42<>CarePlix"
      subject="Report generated from Vitals Scan"
    >
      <Page wrap={false}>
        <View
          style={{
            position: "relative",
            height: "100%",
            width: "100%",
            padding: 56,
            flexDirection: "column",
            alignItems: "stretch",
            justifyContent: "space-between",
          }}
        >
          <Image
            source={Cover}
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              objectFit: "cover",
            }}
          />
          <Image source={CompanyLogo} style={{ width: 160 }} />
          <View>
            <Text
              style={{
                fontFamily: "Poppins",
                fontSize: 40,
                lineHeight: 1.2,
                fontWeight: "semibold",
                color: "#fff",
              }}
            >
              {"Digital Health\nAssesment Report"}
            </Text>
            <Text
              style={{
                marginTop: 24,
                fontFamily: "Poppins",
                fontSize: 16,
                lineHeight: 1.5,
                color: "#ffffffcc",
              }}
            >
              {`Date: ${result.timestamp.toLocaleDateString(undefined, {
                day: "2-digit",
                month: "2-digit",
                year: "2-digit",
              })}`}
            </Text>
          </View>
        </View>
      </Page>
      <Page style={styles.page}>
        <View fixed style={styles.header}>
          <Text
            style={{
              fontFamily: "Poppins",
              fontSize: 24,
              lineHeight: 1.2,
              fontWeight: "medium",
              color: "#0D212C",
            }}
          >
            {"Digital Health\nAssesment Report"}
          </Text>
          <View style={{ alignItems: "flex-end" }}>
            {[
              {
                label: "Assessment Date",
                value: result.timestamp.toLocaleDateString(undefined, {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                }),
              },
              {
                label: "Assessment Time",
                value: result.timestamp.toLocaleTimeString(undefined, {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: false,
                }),
              },
            ].map(({ label, value }) => (
              <Text
                key={label.toLowerCase().replaceAll(" ", "-")}
                style={{ fontFamily: "Poppins", fontSize: 10, lineHeight: 1.5 }}
              >
                <Text style={{ color: "#5B7E95" }}>{label}: </Text>
                <Text style={{ color: "#0D212C" }}>{value}</Text>
              </Text>
            ))}
          </View>
        </View>
        <View style={styles.contentContainer}>
          <View style={[styles.rowContainer, { gap: 12 }]}>
            <View style={{ flexGrow: 1, marginTop: 30 }}>
              {[
                { label: "Age", value: `${age} years` },
                { label: "Gender", value: gender },
                { label: "Height", value: `${height} cms` },
                { label: "Weight", value: `${weight} kgs` },
              ].map(({ label, value }, index) => (
                <View
                  key={label.toLowerCase().replaceAll(" ", "-")}
                  style={{
                    paddingTop: index > 0 ? 12 : 0,
                    paddingBottom: 12,
                    borderBottomWidth: 0.5,
                    borderBottomColor: "#D4D4D4",
                    flexDirection: "row",
                    fontFamily: "Poppins",
                    fontSize: 10,
                    lineHeight: 1.2,
                  }}
                >
                  <Text
                    style={{
                      width: 48,
                      color: "#0D212C",
                      fontWeight: "semibold",
                    }}
                  >
                    {label}:
                  </Text>
                  <Text style={{ color: "#445D6A" }}>{value}</Text>
                </View>
              ))}
            </View>
            <View style={{ alignItems: "center" }}>
              <Text
                style={{
                  marginBottom: 12,
                  fontFamily: "Poppins",
                  fontSize: 14,
                  lineHeight: 1.2,
                  fontWeight: "semibold",
                  color: "#0D212C",
                }}
              >
                Overall Health Score
              </Text>
              <View
                style={{
                  padding: 16,
                  borderRadius: 8,
                  borderWidth: 0.5,
                  borderColor: "#E0E0E0",
                  backgroundColor: "#F6F6F6",
                }}
              >
                {constants["Wellbeing Score"].ranges
                  .slice(0, -1)
                  .reverse()
                  .map((r, index, list) => (
                    <View
                      key={`score-range-${index}`}
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        borderTopWidth: index > 0 ? 0.5 : 0,
                        borderTopColor: "#E7E9EA",
                      }}
                    >
                      <View style={{ width: 60, alignItems: "center" }}>
                        {healthScoreRangeLabel === r.label && (
                          <Text
                            style={{
                              fontFamily: "Poppins",
                              fontWeight: "semibold",
                              fontSize: 16,
                              lineHeight: 1.25,
                              color: r.color,
                            }}
                          >
                            {result.health_score}%
                          </Text>
                        )}
                      </View>
                      <View
                        style={{
                          marginRight: 16,
                          height: 35,
                          width: 5,
                          backgroundColor: r.color,
                          borderTopLeftRadius: index > 0 ? 0 : 20,
                          borderTopRightRadius: index > 0 ? 0 : 20,
                          borderBottomLeftRadius:
                            index < list.length - 1 ? 0 : 20,
                          borderBottomRightRadius:
                            index < list.length - 1 ? 0 : 20,
                        }}
                      >
                        {healthScoreRangeLabel === r.label && (
                          <Text
                            style={{
                              position: "absolute",
                              top: 5,
                              left: 7.5,
                              fontFamily: "Poppins",
                              fontWeight: "semibold",
                              fontSize: 20,
                              color: r.color,
                            }}
                          >
                            {">"}
                          </Text>
                        )}
                      </View>
                      <Text
                        style={{
                          fontFamily: "Poppins",
                          fontSize: 10,
                          lineHeight: 1.2,
                          color:
                            healthScoreRangeLabel === r.label
                              ? r.color
                              : "#0D212C",
                        }}
                      >
                        {r.label}
                      </Text>
                    </View>
                  ))}
              </View>
            </View>
          </View>
          <View
            style={{
              flexDirection: "row",
              gap: 12,
              marginTop: 16,
              marginBottom: "5%",
            }}
          >
            {[
              { icon: MindScore, name: "Mind", value: result.mind_score },
              { icon: HeartScore, name: "Heart", value: result.heart_score },
              {
                icon: PhysiologicalScore,
                name: "Physiological",
                value: result.physiological_score,
              },
            ].map((score) => (
              <View
                key={score.name}
                style={{
                  flex: 1,
                  padding: 12,
                  borderRadius: 8,
                  borderWidth: 0.5,
                  borderColor: "#E0E0E0",
                  backgroundColor: "#F6F6F6",
                }}
              >
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <Image
                    source={score.icon}
                    style={{
                      height: 56,
                      width: 56,
                      padding: 12,
                      borderRadius: 6,
                      backgroundColor: "#fff",
                    }}
                  />
                  <Text
                    style={{
                      marginLeft: 12,
                      fontFamily: "Poppins",
                      fontSize: 12,
                      lineHeight: 1.2,
                      fontWeight: "semibold",
                      color: "#3EBFC8",
                    }}
                  >
                    {score.name}
                  </Text>
                </View>
                <Text
                  style={{
                    marginTop: 12,
                    fontFamily: "Poppins",
                    fontSize: 22,
                    lineHeight: 1.2,
                    fontWeight: "semibold",
                    color: "#0D212C",
                  }}
                >
                  <Text>{`${Math.round(score.value)} / `}</Text>
                  <Text style={{ color: "#27C178" }}>100</Text>
                </Text>
              </View>
            ))}
          </View>
          <View
            style={{
              marginTop: 24,
              padding: 24,
              borderRadius: 10,
              backgroundColor: "#f1f1f1",
            }}
          >
            <Text style={[styles.normalText, { fontWeight: "bold" }]}>
              Disclaimer
            </Text>
            <Text
              style={[styles.smallText, { marginTop: 8, textAlign: "justify" }]}
            >
              For Investigational Use Only. This scan is not a substitute for
              the clinical judgment of a healthcare professional. This scan is
              intended to improve your awareness of general wellness. This scan
              does not diagnose, treat, mitigate or prevent any disease,
              symptom, disorder or abnormal physical state. Consult with a
              healthcare professional or emergency services if you believe you
              may have a medical issue.
            </Text>
          </View>
        </View>
        <View style={styles.contentContainer} break>
          <Image
            source={KBVBanner}
            style={{ width: "100%", marginBottom: 16 }}
          />
          <Parameter
            containerStyle={{ paddingTop: 0, borderTopWidth: 0 }}
            icon={RespirationRate}
            name="Respiration Rate"
            desc="The respiration rate is the number of breaths taken in a minute. The rate is usually measured when a person is at rest and simply involves counting the number of breaths for one minute by counting how many times the chest rises."
            value={result.resp_rate}
            ranges={constants["Respiration Rate"].ranges}
            unit={constants["Respiration Rate"].unit}
          />
          <Parameter
            icon={OxygenSaturation}
            name="Oxygen Saturation"
            desc="Oxygen saturation also known as  (SpO2) is the measurement used to assess the amount of oxygen being carried by the blood and delivered to body's tissues."
            value={result.oxy_sat_prcnt}
            ranges={constants["Oxygen Saturation"].ranges}
            unit={constants["Oxygen Saturation"].unit}
          />
          <Parameter
            icon={BMI}
            name="BMI"
            desc="Body Mass Index (BMI) is a measurement based on your weight and height. It is more of an indicator than a direct measurement of your total body fat."
            value={result.bmi}
            ranges={constants["BMI"].ranges}
            unit={constants["BMI"].unit}
            rangeConfig={{ inverted: true, diff: 0.1 }}
          />
          <View
            style={{ padding: 15, borderRadius: 8, backgroundColor: "#F6F6F6" }}
          >
            <Text style={[styles.normalText, { fontWeight: "bold" }]}>
              Disclaimer
            </Text>
            <Text
              style={[
                styles.smallText,
                { marginTop: 8, textAlign: "justify", fontSize: 8 },
              ]}
            >
              For Investigational Use Only. This scan is not a substitute for
              the clinical judgment of a healthcare professional. This scan is
              intended to improve your awareness of general wellness. This scan
              does not diagnose, treat, mitigate or prevent any disease,
              symptom, disorder or abnormal physical state. Consult with a
              healthcare professional or emergency services if you believe you
              may have a medical issue.
            </Text>
          </View>
        </View>
        <View style={styles.contentContainer} break>
          <Image
            source={HHBanner}
            style={{ width: "100%", marginBottom: 16 }}
          />
          <Parameter
            containerStyle={{ paddingTop: 0, borderTopWidth: 0 }}
            icon={HeartRate}
            name="Heart Rate"
            desc="The heart rate is the number of times heart beats in a minute (bpm).  It is an important indicator of the body's cardiovascular health and can provide insight into various physiological and medical condition.  A “Normal” resting heart rate for adults ranges from 60 to 100 bpm."
            value={result.heart_rate}
            ranges={constants["Heart Rate"].ranges[result.posture]}
            unit={constants["Heart Rate"].unit}
          />
          <Parameter
            icon={SDNN}
            name="SDNN"
            desc="The standard deviation of all of the NN intervals (the time intervals between each heartbeat). Higher numbers usually indicate that your body is coping better with stress."
            value={result.sdnn}
            ranges={constants["SDNN"].ranges}
            unit={constants["SDNN"].unit}
          />
          <Parameter
            icon={RMSSD}
            name="RMSSD"
            desc="The root mean square of successive intervals between normal heartbeats (RMSSD) and is another measure of heart rate variability ( HRV). RMSSD is obtained by first calculating each successive time intervals between heartbeats in msec."
            value={result.rmssd}
            ranges={constants["RMSSD"].ranges}
            unit={constants["RMSSD"].unit}
          />
          <Parameter
            icon={PNN50}
            name="PNN50"
            desc="PNN50 is a measure of the number of short term fluctuations in the heart rate and is considered to reflect changes in (HRV)  driven by the sympathetic branch of the autonomic nervous system, that prepares the body for stress and physical activity. PNN50 lower than 3% is indicative of high risk."
            value={result.pNN50_per}
            ranges={constants["PNN50"].ranges}
            unit={constants["PNN50"].unit}
          />
          <Parameter
            icon={CardiacOutput}
            name="Cardiac Output"
            desc="The amount of blood  the heart pumps through the circulatory system in a minute. Cardiac output is generally 4-8 L/min in an rest."
            value={result.cardiac_out}
            ranges={constants["Cardiac Output"].ranges}
            unit={constants["Cardiac Output"].unit}
          />
        </View>
        <View style={styles.contentContainer} break>
          <Parameter
            icon={MAP}
            name="Mean Arterial Pressure"
            desc="MAP is the average arterial pressure throughout one heart cycle. MAP is an important measurement that accounts for flow, resistance, and pressure within your arteries. It allows doctors to evaluate how well blood flows through your body and whether it's reaching all your major organs."
            value={result.map}
            containerStyle={{ paddingTop: 0, borderTopWidth: 0 }}
            ranges={constants["Mean Arterial Pressure"].ranges}
            unit={constants["Mean Arterial Pressure"].unit}
          />
          <Parameter
            icon={HeartUtilisation}
            name="Heart Utilized"
            desc="Heart utilization is the percentage of overall heart usage to its maximum peak heart rate. Generally < 50% utilization at rest and 51-85% utilization at moderate intensity is considered as normal."
            value={result.heart_utilized}
            ranges={constants["Heart Utilized"].ranges}
            unit={constants["Heart Utilized"].unit}
          />
          <Parameter
            icon={HRMax}
            name="Heart Rate Max"
            desc="Maximum heart rate is the highest number of beats your heat can pump per minute when it's under high stress (physical or otherwise). Knowing your max HR can help you track your fitness progress and determine your target heart rate for exercise intensity."
            value={`${result.HRMax} ${constants["Heart Rate Max"].unit}`}
            noRange
          />
          <Parameter
            icon={HRR}
            name="Heart Rate Reserve"
            desc="Heart rate reserve is the difference between your maximum (peak) heart rate and your resting heart rate. The more fit you are, the lower your resting heart rate will be. When you have a low resting heart rate, your heart rate reserve is high."
            value={`${result.HRR} ${constants["Heart Rate Reserve"].unit}`}
            noRange
          />
          <Parameter
            icon={THRR}
            name="Target Heart Rate Range"
            desc="To achieve a certain level of exertion, there is a minimum number of heartbeats required within a specific timeframe."
            value={`${result.THRR} ${constants["Target HR Range"].unit}`}
            noRange
          />
          <Parameter
            icon={VO2MAX}
            name="VO2 Max"
            desc={
              "VO2 Max is the maximum rate of oxygen consumption measured during incremental exercise. Maximal oxygen consumption reflects your overall cardiorespiratory fitness, and is an excellent way to assess your fitness level.\n\nA good V02 max for a man between ages 30 and 39 is 41 to 44.9\nFor women of the same age, a good V02 max is between 31.5 to 35.6"
            }
            value={`${result.vo2max} ${constants["VO₂ Max"].unit}`}
            noRange
          />
          <View
            style={{ padding: 15, borderRadius: 8, backgroundColor: "#F6F6F6" }}
          >
            <Text style={[styles.normalText, { fontWeight: "bold" }]}>
              Disclaimer
            </Text>
            <Text
              style={[
                styles.smallText,
                { marginTop: 8, textAlign: "justify", fontSize: 8 },
              ]}
            >
              For Investigational Use Only. This scan is not a substitute for
              the clinical judgment of a healthcare professional. This scan is
              intended to improve your awareness of general wellness. This scan
              does not diagnose, treat, mitigate or prevent any disease,
              symptom, disorder or abnormal physical state. Consult with a
              healthcare professional or emergency services if you believe you
              may have a medical issue.
            </Text>
          </View>
        </View>
        <View fixed style={styles.footer}>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Image source={CompanyLogo} style={{ width: 80 }} />
            <View
              style={{
                alignSelf: "stretch",
                width: 0.5,
                backgroundColor: "#fff",
                marginHorizontal: 13,
              }}
            />
            <Text
              style={{
                fontFamily: "Poppins",
                fontSize: 10,
                lineHeight: 1.5,
                fontWeight: "semibold",
                color: "#fff",
              }}
            >
              {"Where Innovation\nMeets Care"}
            </Text>
          </View>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <View>
              <Text
                style={{
                  fontFamily: "Poppins",
                  fontSize: 8,
                  lineHeight: 1.5,
                  fontWeight: "semibold",
                  color: "#fff",
                }}
              >
                Download App Now
              </Text>
              <Image source={AppStore} style={{ marginTop: 8, width: 100 }} />
              <Image source={PlayStore} style={{ marginTop: 8, width: 100 }} />
            </View>
            <Image
              source={QRCode}
              style={{ marginLeft: 18, width: 96, height: 96 }}
            />
          </View>
        </View>
      </Page>
    </Document>
  );
};

const Range = ({
  ranges = [
    { point: 0, label: "Start", color: "#ccc" },
    { point: 100, label: "End", color: "#000" },
  ],
  selectedIndex,
  className = "",
}) => {
  selectedIndex -= ranges.length - 1 === selectedIndex;
  return (
    <div className={`relative grid grid-flow-col auto-cols-fr ${className}`}>
      {ranges.slice(0, -1).map((rangeItem, index) => (
        <div key={`range-${index}`} className="flex flex-col justify-end">
          <h6
            className="pb-2 px-1 text-center text-xxs uppercase truncate"
            style={
              index === selectedIndex
                ? {
                    color: ranges[selectedIndex].color,
                    backgroundImage: `linear-gradient(to top, ${ranges[selectedIndex].color}26, transparent)`,
                  }
                : { color: "#6E7A80" }
            }
          >
            {rangeItem.label}
          </h6>
          <div
            className="h-1"
            style={
              index === selectedIndex
                ? { backgroundColor: ranges[selectedIndex].color }
                : { backgroundColor: "#F2F2F7" }
            }
          />
          <p className="mt-2 text-[#6E7A80] text-xxs">{rangeItem.point}</p>
        </div>
      ))}
      <p className="absolute bottom-0 right-0 text-[#6E7A80] text-xxs">
        {ranges[ranges.length - 1].point}
      </p>
    </div>
  );
};

const ListItemContainer = ({
  disabled = false,
  label = "",
  defaultExpanded = false,
  title,
  desc,
  className = "",
  ...props
}) => {
  return (
    <Disclosure
      defaultOpen={defaultExpanded}
      as="div"
      className="p-4 shadow-sm rounded-lg bg-white"
    >
      <Disclosure.Button as="div" disabled={disabled}>
        {({ open }) => (
          <>
            <div className="flex items-start justify-between space-x-1">
              <h2 className="text-[#0D212C] text-base leading-tight font-semibold">
                {label}
              </h2>
              <Chevron
                className={`h-3 w-3 text-[#6E7A80] transition-transform duration-300 ${
                  open ? "rotate-180" : "rotate-0"
                }`}
              />
            </div>
            <hr className="my-2 h-[0.5px] bg-[#E5E5EA]" />
            {props.children}
          </>
        )}
      </Disclosure.Button>
      <Disclosure.Panel>
        {typeof title === "string" && (
          <h5 className="mt-4 text-[#0D212C] text-sm font-semibold">{title}</h5>
        )}
        {typeof desc === "string" && (
          <p className="mt-1 text-[#6E7A80] text-xs whitespace-pre-line">
            {desc}
          </p>
        )}
      </Disclosure.Panel>
    </Disclosure>
  );
};

const ScanResult = () => {
  const { state } = useLocation();

  const downloadReport = useCallback(async () => {
    try {
      const reportPDFb64 = await new Promise(async (resolve, reject) => {
        try {
          const ageDifMs = Date.now() - new Date(state?.dob ?? 0).getTime();
          const ageDate = new Date(ageDifMs);
          const age = Math.abs(ageDate.getUTCFullYear() - 1970);
          const gen = `${state?.gender
            ?.slice(0, 1)
            .toUpperCase()}${state?.gender?.slice(1)}`;
          const vitalsData = {
            timestamp: new Date(
              state?.result?.entry_time?.replace?.(" ", "T") ?? 0
            ),
            posture: state?.result?.posture?.toLowerCase?.(),
            mind_score: state?.result?.metadata.mental_wellbeing_score,
            heart_score: state?.result?.metadata.overall_heart_score,
            physiological_score: state?.result?.metadata.physiological_score,
            health_score: state?.result?.wellness_score,
            resp_rate: state?.result?.vitals.resp_rate,
            oxy_sat_prcnt: state?.result?.vitals.oxy_sat_prcnt,
            heart_rate: state?.result?.vitals.heart_rate,
            bmi: state?.result?.metadata.physiological_scores.bmi,
            sdnn:
              state?.result?.metadata.heart_scores.sdnn !== "--"
                ? Math.round(state?.result?.metadata.heart_scores.sdnn)
                : "--",
            rmssd:
              state?.result?.metadata.heart_scores.rmssd !== "--"
                ? Math.round(state?.result?.metadata.heart_scores.rmssd)
                : "--",
            pNN50_per: state?.result?.metadata.heart_scores.pNN50_per,
            cardiac_out: state?.result?.metadata.cardiovascular.cardiac_out,
            map: state?.result?.metadata.cardiovascular.map,
            heart_utilized: state?.result?.metadata.heart_scores.heart_utilized,
            HRMax: state?.result?.metadata.heart_scores.HRMax,
            HRR: state?.result?.metadata.heart_scores.HRR,
            THRR: state?.result?.metadata.heart_scores.THRR,
            vo2max: state?.result?.metadata.physiological_scores.vo2max,
          };
          const blob = await pdf(
            <PDFReport
              age={age}
              gender={gen}
              height={state?.height ?? 0}
              weight={state?.weight ?? 0}
              result={vitalsData}
            />
          ).toBlob();
          const reader = new FileReader();
          reader.onerror = () =>
            reject(
              new Error(reader.error?.message || "Base64 Conversion Failed.")
            );
          reader.onload = () =>
            resolve(reader.result?.replace?.(/^data:.+;base64,/, "") || "");
          reader.readAsDataURL(blob);
        } catch (err) {
          reject(err);
        }
      });
      if (state?.is_webview) {
        window.parent?.postMessage?.(`download-report:::${reportPDFb64}`, "*");
        window.ReactNativeWebView?.postMessage?.(
          `download-report:::${reportPDFb64}`
        );
      }
    } catch (err) {
      console.error(err);
    }
  }, [state]);

  const goBack = useCallback(() => {
    if (state?.is_webview) {
      window.parent?.postMessage?.("go-back:::scan-result", "*");
      window.ReactNativeWebView?.postMessage?.("go-back:::scan-result");
    } else
      window.location.replace(
        new URL(
          `${state?.callback_url}${
            state?.callback_url.slice(-1) !== "/" ? "/" : ""
          }vitals-scan-result?${new URLSearchParams({
            data: JSON.stringify(state?.result),
          })}`
        )
      );
  }, [state]);

  const WellbeingScore = useCallback(() => {
    const score = state?.result?.wellness_score;
    const selectedScoreRange = constants["Wellbeing Score"].ranges.reduce(
      (p, c) => (score > c.point ? c : p)
    );
    return (
      <>
        <div className="flex flex-col items-center">
          <ProgressCircle
            size={120}
            value={score}
            color={selectedScoreRange.color}
          />
          <h2 className="mt-2 text-[#0D212C] text-lg leading-tight font-bold">
            Wellbeing Score
          </h2>
          <h4
            className="mt-1 text-lg leading-tight font-bold uppercase"
            style={{ color: selectedScoreRange.color }}
          >
            {selectedScoreRange.label}
          </h4>
        </div>
        <p className="mt-4 p-4 shadow-sm rounded-lg bg-white text-[#6E7A80] text-sm leading-[1.125rem]">
          {selectedScoreRange.info}
        </p>
      </>
    );
  }, [state?.result?.wellness_score]);

  const BMI = useCallback(() => {
    const bmi = state?.result?.metadata.physiological_scores.bmi;
    const selectedBMIRangeIndex = constants.BMI.ranges.reduce(
      (p, c, ci) => (bmi > c.point ? ci : p),
      0
    );
    return (
      <ListItemContainer
        label="BMI"
        title={`Your BMI is ${constants.BMI.ranges[selectedBMIRangeIndex].label}`}
        desc={
          constants.BMI.info +
          "\n\n" +
          constants.BMI.ranges[selectedBMIRangeIndex].desc
        }
      >
        <div className="py-1 flex items-center justify-between space-x-1.5">
          <div className="shrink-0 basis-1/4">
            <h4 className="text-[#3ABBC7] text-2xl font-bold">{bmi}</h4>
            <h5 className="text-[#3E4E56] text-xs">{constants.BMI.unit}</h5>
          </div>
          <Range
            className="grow"
            ranges={constants.BMI.ranges}
            selectedIndex={selectedBMIRangeIndex}
          />
        </div>
      </ListItemContainer>
    );
  }, [state?.result?.metadata.physiological_scores.bmi]);

  const StressIndex = useCallback(() => {
    const stressLevel = state?.result?.metadata.heart_scores.stress_index;
    const selectedRangeIndex = constants["Stress Level"].ranges.reduce(
      (p, c, ci) => (stressLevel > c.point ? ci : p),
      0
    );
    const selectedRange = constants["Stress Level"].ranges[selectedRangeIndex];
    return (
      <div className="p-4 shadow-sm rounded-lg bg-white">
        <div className="flex items-center justify-between space-x-4">
          {selectedRange.icon}
          <div className="grow text-base leading-tight font-semibold">
            <h2 className="text-[#0D212C]">Stress Level</h2>
            <h4
              className="mt-1 uppercase"
              style={{ color: selectedRange.color }}
            >
              {selectedRange.label}
            </h4>
          </div>
          <div className="shrink-0 text-right">
            <h4 className="text-[#3ABBC7] text-2xl font-bold">{stressLevel}</h4>
            <h5 className="text-[#3E4E56] text-xs">
              {constants["Stress Level"].unit}
            </h5>
          </div>
        </div>
        <div className="mt-6">
          <h5 className="text-[#0D212C] text-sm font-semibold">
            Your Stress Level is {selectedRange.label}
          </h5>
          <p className="mt-1 text-[#6E7A80] text-xs whitespace-pre-line">
            {constants["Stress Level"].description}
          </p>
        </div>
      </div>
    );
  }, [state?.result?.metadata.heart_scores.stress_index]);

  return (
    <section className="min-h-screen px-5 pt-16 pb-32">
      <div className="fixed top-0 left-0 right-0 z-20 flex items-center space-x-2 px-4 py-3 border-b border-[#D6D9DA] bg-white text-[#0D212C]">
        <button className="shrink-0 h-6 w-6" type="button" onClick={goBack}>
          <Close className="h-full w-full" />
        </button>
        <h2 className="text-sm font-semibold">Scan Result</h2>
      </div>
      <WellbeingScore />
      <p className="max-w-max mx-auto mt-6 mb-3 py-3 px-6 shadow-sm rounded-lg border border-[#36C0C9] bg-[#F4FFFD] text-[#0D212C] text-sm font-semibold">
        Key Body Vitals
      </p>
      <div className="mt-3 space-y-3">
        <BMI />
        {[
          { label: "Heart Rate", value: state?.result?.vitals.heart_rate },
          { label: "Respiration Rate", value: state?.result?.vitals.resp_rate },
          {
            label: "Oxygen Saturation",
            value: state?.result?.vitals.oxy_sat_prcnt,
          },
        ].map((item, index) => {
          const ranges =
            item.label === "Heart Rate"
              ? constants[item.label].ranges[
                  state?.result?.posture ?? "resting"
                ]
              : constants[item.label].ranges;
          const selectedRangeIndex = ranges.reduce(
            (p, c, ci) => (item.value >= c.point ? ci : p),
            0
          );
          return (
            <ListItemContainer
              key={`section-item-${index}`}
              label={item.label}
              title={`Your ${item.label} is ${ranges[selectedRangeIndex].label}`}
              desc={
                constants[item.label].info +
                "\n\n" +
                ranges[selectedRangeIndex].desc
              }
            >
              <div className="py-1 flex items-center justify-between space-x-1.5">
                <div className="shrink-0 basis-1/4">
                  <h4 className="text-[#3ABBC7] text-2xl font-bold">
                    {item.value}
                  </h4>
                  <h5 className="text-[#3E4E56] text-xs">
                    {constants[item.label].unit}
                  </h5>
                </div>
                <Range
                  className="grow"
                  ranges={ranges}
                  selectedIndex={selectedRangeIndex}
                />
              </div>
            </ListItemContainer>
          );
        })}
        <StressIndex />
      </div>
      <div className="mt-3 p-4 rounded-lg bg-[#FFFDE7] border border-[#FCE484]">
        <h3 className="text-base font-semibold text-[#3E4E56]">Disclaimer</h3>
        <p className="mt-2 text-xs text-left text-[#3E4E56]">
          For Investigational Use Only. This scan is not a substitute for the
          clinical judgment of a healthcare professional. This scan is intended
          to improve your awareness of general wellness. This scan does not
          diagnose, treat, mitigate or prevent any disease, symptom, disorder or
          abnormal physical state. Consult with a healthcare professional or
          emergency services if you believe you may have a medical issue.
        </p>
      </div>
      <div className="fixed bottom-0 left-0 right-0 z-20 h-[7.5rem] p-5 rounded-t-[20px] shadow-drop bg-white">
        <button
          className="w-full px-4 py-3.5 rounded-xl bg-[#3ABBC7] text-white text-sm font-bold"
          type="button"
          onClick={downloadReport}
        >
          Download Report
        </button>
      </div>
    </section>
  );
};

export default ScanResult;
