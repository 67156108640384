import {
  Outlet,
  RouterProvider,
  ScrollRestoration,
  createBrowserRouter,
} from "react-router-dom";

import NotFound from "./screens/404";
import StartScan from "./screens/StartScan";
import BasicDetails from "./screens/BasicDetails";
import SelectPosture from "./screens/SelectPosture";
import ScanInstructions from "./screens/ScanInstructions";
import FingerScan from "./screens/FingerScan";
import ScanResult from "./screens/ScanResult";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <ScrollRestoration />
        <Outlet />
      </>
    ),
    children: [
      { index: true, element: <NotFound /> },
      { path: "start-scan", element: <StartScan /> },
      { path: "basic-details", element: <BasicDetails /> },
      { path: "select-posture", element: <SelectPosture /> },
      { path: "scan-instructions", element: <ScanInstructions /> },
      { path: "finger-scan", element: <FingerScan /> },
      { path: "scan-result", element: <ScanResult /> },
      { path: "*", element: <NotFound /> },
    ],
    errorElement: <NotFound />,
  },
]);

const App = () => <RouterProvider router={router} />;

export default App;
