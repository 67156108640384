import { useNavigate, useSearchParams } from "react-router-dom";
import LoadingScreen from "../components/LoadingScreen";
import { useEffect } from "react";

const toBoolean = (boolish = "") => boolish === "true";

const StartScan = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    try {
      const data = {};
      const dob = searchParams.get("dob");
      const gender = searchParams.get("gender")?.toLowerCase?.();
      const height = searchParams.get("height");
      const weight = searchParams.get("weight");
      const posture = searchParams.get("posture")?.toLowerCase?.();
      const is_webview = searchParams.get("is_webview");
      const show_result = searchParams.get("show_result");
      const callback_url = searchParams.get("callback_url");
      const partner_client_id = searchParams.get("partner_client_id");
      const partner_key = searchParams.get("partner_key");
      const partner_secret = searchParams.get("partner_secret");
      const scan_token = searchParams.get("scan_token");

      if (dob?.length > 0) {
        const age = Math.abs(
          new Date(Date.now() - new Date(dob).getTime()).getUTCFullYear() - 1970
        );
        if (isNaN(age)) console.error("Invalid Date of Birth");
        else if (age < 18 || age > 110)
          console.error("Age must be between 18 and 110");
        else data.dob = dob;
      } else console.error("Invalid Date of Birth");

      if (gender?.length > 0 && /^(male|female|others)$/.test(gender))
        data.gender = gender;
      else console.error("Invalid Gender");

      if (height?.length > 0 && /^\d{1,3}(\.\d{1,2})?$/.test(height)) {
        if (height < 30 || height > 250)
          console.error("Height must be between 30 and 250 cms");
        else data.height = height;
      } else console.error("Invalid Height");

      if (weight?.length > 0 && /^\d{1,3}(\.\d{1,2})?$/.test(weight)) {
        if (weight < 20 || weight > 220)
          console.error("Weight must be between 20 and 220 kgs");
        else data.weight = weight;
      } else console.error("Invalid Weight");

      if (
        posture?.length > 0 &&
        /^(resting|standing|walking|exercising)$/.test(posture)
      )
        data.posture = posture;
      else console.error("Invalid Posture");

      data.is_webview = toBoolean(is_webview);
      data.show_result = toBoolean(show_result);

      try {
        if (callback_url?.length > 0)
          data.callback_url = new URL(callback_url).toString();
        else throw new Error("Invalid Callback URL");
      } catch (err) {
        console.error(err.message);
      }

      if (partner_client_id?.length > 0)
        data.partner_client_id = partner_client_id;
      if (partner_key?.length > 0) data.partner_key = partner_key;
      if (partner_secret?.length > 0) data.partner_secret = partner_secret;
      if (scan_token?.length > 0) data.scan_token = scan_token;

      const data_keys = Object.keys(data);
      const navigationOptions = { state: data, replace: true };
      if (
        ["dob", "gender", "height", "weight"].every((v) =>
          data_keys.includes(v)
        )
      ) {
        if (data_keys.includes("posture"))
          navigate("/scan-instructions", navigationOptions);
        else navigate("/select-posture", navigationOptions);
      } else navigate("/basic-details", navigationOptions);
    } catch (err) {
      console.error(err.message);
    }
  }, [navigate, searchParams]);

  return <LoadingScreen />;
};

export default StartScan;
