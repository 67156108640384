import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { ReactComponent as BackArrow } from "../assets/icons/arrow-left.svg";
import { ReactComponent as Loader } from "../assets/icons/Loader.svg";
import { ReactComponent as InstructionFingerScan } from "../assets/icons/finger-scan.svg";

const ScanInstructions = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);

  const startScan = async () => {
    try {
      setLoading(true);
      if (state?.scan_token?.length > 0) navigate("/finger-scan", { state });
      else {
        const scanTokenResp = await fetch(
          "https://sdk-staging.uae.careplix.com/vitals/create-token",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: state.partner_secret,
            },
            body: JSON.stringify({
              api_key: state.partner_key,
              employee_id: state.partner_client_id,
            }),
          }
        );
        const scanTokenRespJSON = await scanTokenResp.json();
        if (scanTokenRespJSON?.statusCode?.toString().startsWith("2")) {
          navigate("/finger-scan", {
            state: { ...state, scan_token: scanTokenRespJSON.scan_token },
          });
        } else
          throw new Error(
            scanTokenRespJSON?.message ?? "Error in Fetching the Scan Token"
          );
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="min-h-screen px-5 pt-16 pb-32">
      <div className="fixed top-0 left-0 right-0 flex items-center space-x-2 px-4 py-3 border-b border-[#D6D9DA] bg-white text-[#0D212C]">
        <Link
          className="shrink-0 h-6 w-6"
          to="/select-posture"
          state={{ ...state }}
        >
          <BackArrow className="h-full w-full" />
        </Link>
        <h2 className="text-sm font-semibold">Wellness</h2>
      </div>
      <InstructionFingerScan className="h-36 w-36 mt-4 mx-auto rounded-3xl bg-white" />
      <h3 className="mt-8 text-[#0D212C] text-lg leading-tight font-bold text-center">
        Finger Scan
      </h3>
      <div className="mt-3 p-4 rounded-lg bg-white">
        <div className="flex items-center space-x-4">
          <svg
            className="shrink-0 h-12 w-12 p-3 rounded-lg border border-[#EDEDF5] bg-[#F9FAFB] text-[#3ABBC7]"
            viewBox="0 0 11 24"
            fill="none"
          >
            <path
              d="M10.4839 21.41L10.1486 22.7808C9.14255 23.1779 8.33894 23.4801 7.74054 23.6879C7.14149 23.8963 6.44543 24 5.65237 24C4.43448 24 3.48719 23.7017 2.81157 23.1091C2.13594 22.5141 1.79803 21.7602 1.79803 20.8456C1.79803 20.4915 1.82255 20.1274 1.87352 19.7563C1.92493 19.3849 2.00667 18.9665 2.11852 18.4989L3.37577 14.0489C3.48762 13.6228 3.58269 13.2191 3.65884 12.8373C3.73606 12.4578 3.77327 12.1087 3.77327 11.7945C3.77327 11.2262 3.65561 10.8287 3.42137 10.6052C3.18713 10.3824 2.74015 10.2692 2.07658 10.2692C1.75156 10.2692 1.41752 10.3213 1.07702 10.4219C0.735011 10.523 0.442693 10.6207 0.197266 10.7115L0.533464 9.33956C1.35707 9.00422 2.14433 8.71706 2.89739 8.47874C3.65045 8.23976 4.36199 8.12017 5.03504 8.12017C6.24453 8.12017 7.17784 8.41249 7.83325 8.99712C8.48865 9.58219 8.81625 10.3406 8.81625 11.2752C8.81625 11.4686 8.79473 11.8093 8.74849 12.2963C8.70332 12.7844 8.61922 13.2315 8.49661 13.6383L7.24474 18.0704C7.14214 18.4264 7.04986 18.8333 6.96963 19.2913C6.88682 19.7462 6.84724 20.0938 6.84724 20.327C6.84724 20.9155 6.97845 21.3173 7.24151 21.5309C7.50651 21.7445 7.96295 21.8507 8.61126 21.8507C8.91562 21.8507 9.26193 21.7967 9.64674 21.6907C10.0305 21.5847 10.3103 21.4915 10.4839 21.41ZM10.8014 2.80273C10.8014 3.57493 10.5104 4.23442 9.92551 4.77668C9.34216 5.32088 8.63922 5.5932 7.8169 5.5932C6.992 5.5932 6.28734 5.32088 5.69732 4.77668C5.10839 4.23421 4.81327 3.57493 4.81327 2.80273C4.81327 2.03203 5.10839 1.37147 5.69732 0.82232C6.28626 0.274035 6.99221 0 7.8169 0C8.63901 0 9.34216 0.27468 9.92551 0.82232C10.5108 1.37147 10.8014 2.03225 10.8014 2.80273Z"
              fill="currentColor"
            />
          </svg>
          <p className="grow text-[#0D212C] text-sm font-semibold">
            How should I prepare for a finger scan?
          </p>
        </div>
        <hr className="my-3 h-px bg-[#EDEDF5]" />
        <div className="text-[#0D212C] text-xs">
          <p>Follow these steps:</p>
          <ol className="mt-2.5 ml-4 space-y-2.5 list-outside list-decimal">
            {[
              "Hold your mobile device in a fixed position throughout the measurement.",
              "Place your index finger fully over the rear camera lens without covering the flashlight.",
              "Apply moderate and constant pressure.",
              "Avoid moving or talking during the measurement.",
              "Ensure there is some light, measurements should not be taken in complete darkness.",
            ].map((listItem, index) => (
              <li key={index}>{listItem}</li>
            ))}
          </ol>
        </div>
      </div>

      <div className="mt-3 p-4 rounded-lg bg-[#FFFDE7] border border-[#FCE484]">
        <h3 className="text-base font-semibold text-[#3E4E56]">Disclaimer</h3>
        <p className="mt-2 text-xs text-left text-[#3E4E56]">
          For Investigational Use Only. This scan is not a substitute for the
          clinical judgment of a healthcare professional. This scan is intended
          to improve your awareness of general wellness. This scan does not
          diagnose, treat, mitigate or prevent any disease, symptom, disorder or
          abnormal physical state. Consult with a healthcare professional or
          emergency services if you believe you may have a medical issue.
        </p>
      </div>

      <div className="fixed bottom-0 left-0 right-0 h-[7.5rem] p-5 rounded-t-[20px] shadow-drop bg-white">
        <button
          className="w-full flex items-center justify-center space-x-3 px-4 py-3.5 rounded-xl bg-[#3ABBC7] text-white text-sm font-bold"
          type="button"
          onClick={startScan}
          disabled={loading}
        >
          <span className="text-xs font-medium">Proceed</span>
          {loading && <Loader className="shrink-0 h-4 w-4" />}
        </button>
      </div>
    </section>
  );
};

export default ScanInstructions;
